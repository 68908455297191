import { isArray } from "lodash";
import { DeviceData } from "../services/DeviceDataService";
import { CustomersDto } from "../services/UserService";
import { TranslationsDictionary } from "src/views/Devices/TranslationProvider";

export type Translator = (
    key: string,
    value: string,
    values?: Record<string, string> | Record<string, DeviceData>
) => string;
export type TranslatorMiddleware = (next: Translator) => Translator;
export type TranslatorFactory = (factoryArgs: {
    customers: CustomersDto;
    dictionary: TranslationsDictionary;
    instance: DataTranslator;
}) => TranslatorMiddleware;

// default translate just returns the value
function translate(
    _key: string,
    value: string,
    _values?: Record<string, string> | Record<string, DeviceData>
) {
    return value;
}

export default class DataTranslator {
    private translator_: Translator;

    shouldTranslate = true;

    constructor(
        customers: CustomersDto,
        private dictionary: TranslationsDictionary,
        translators: TranslatorFactory[]
    ) {
        this.translator_ = translators
            .map((factory) =>
                factory({ customers, dictionary, instance: this })
            )
            .reduce((firstMw, secondMw) => (translator) =>
                firstMw(secondMw(translator))
            )(translate);
    }

    translateKey(key: string): string {
        let translated;
        if (this.shouldTranslate) {
            translated = this.dictionary[key]?.alias;
        }
        return translated || key;
    }

    translate(
        key: string,
        value: string,
        values?: Record<string, string> | Record<string, DeviceData>
    ): string {
        return (
            (this.shouldTranslate && this.translator_(key, value, values)) ||
            value
        );
    }
}

const dictionaryTranslator: TranslatorFactory = ({ dictionary }) => (next) => (
    key,
    value,
    values
) => {
    const translation = dictionary[key]?.translations[value];
    return translation?.translatedValue ?? next(key, value, values);
};

const customersTranslator: TranslatorFactory = ({ customers }) => (next) => (
    key,
    value,
    values
) => {
    if (key === "customer_id"){
        return isArray(value)?((value).map((cust:any)=>customers[cust]?.name??'').length>1? JSON.stringify((value).map((cust:any)=>customers[cust]?.name??'')): customers[value as any]?.name ?? ""): customers[value as any]?.name ?? ""
    }
    if (key === "Dealer" || key === "dealer_id") {
        return customers[value as any]?.name ?? "";
    }

    return next(key, value, values);
};

const customersTranslatorForBasestationKey: TranslatorFactory = ({ customers }) => (next) => (
    key,
    value,
    values
) => {
    if (key === "customer") {
        return customers[value as any]?.name ?? "";
    }

    return next(key, value, values);
};



const defaultTranslators = [customersTranslator, dictionaryTranslator];
const robotTranslators: TranslatorFactory[] = [];
const tabletTranslators: TranslatorFactory[] = [];
const basestationTranslators = [customersTranslatorForBasestationKey];

const mapTranslator = (deviceType:number) => {
    switch (deviceType) {
        case 1:
            return robotTranslators;
        case 2:
            return tabletTranslators;
        case 3:
            return basestationTranslators;
        default:
            return [];
    }
}

export function makeTranslator(
    deviceType: number,
    customers: CustomersDto,
    dictionary: TranslationsDictionary
) {
    return new DataTranslator(customers, dictionary, [
        ...(mapTranslator(deviceType)),
        ...defaultTranslators,
    ]);
}
