import React, { useCallback, useEffect } from "react";
import BaseWidget, {WidgetProps } from "./BaseWidget";
import { useForm } from "react-hook-form";
import ConfigurationsService  from "src/services/ConfigurationsService";
import { useAsync, useToast} from "src/hooks";
import { Grid, Switch } from "@material-ui/core";

type BreakdownInput = {
    isBreakdown:boolean
};

function triggerBreakdown(input: BreakdownInput) {
    return ConfigurationsService.triggerBreakdownGreetings(input.isBreakdown);
}

export default function BreakdownWidget({ widgetName, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<BreakdownInput>({
        defaultValues: { isBreakdown: false },
    });


    const isBreakdown = watch("isBreakdown");

    useEffect(() => {
        register("isBreakdown", {});
    }, [register]);

    const { displayToast } = useToast();


    const onComplete = useCallback(
        () => {
            reset();
            displayToast({
                message: `Successfully changed greetings`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [reset]
    );

    const { exec, pending, error } = useAsync(
        triggerBreakdown,
        {
            immediate: false,
            onComplete,
        }
    );

  
    return (
        <BaseWidget
            widgetName={widgetName}
            title="Trigger breakdown"
            subTitle="Change all Zendesk lines` greeting to emergency one and back."
            onSubmit={handleSubmit(exec)}
            loading={pending}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
           <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Default</Grid>
                <Grid item>
                    <Switch
                    checked={isBreakdown}
                    onChange={(ev:any)=>setValue('isBreakdown',ev.target.checked)} 
                    value="checked" 
                    />
                </Grid>
                <Grid item>Breakdown</Grid>
            </Grid>
        </BaseWidget>
    );
}
