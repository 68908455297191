import React, { useMemo } from "react";
import { FilterProps } from "react-table";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DeviceDto } from "../../../services/DeviceDataService";
import { useTranslator } from "../TranslationProvider";

const columnIds = ["Server online", "vpn_status"];
export function OnlineColumnFilter({
    column: { filterValue, setFilter },
}: FilterProps<DeviceDto>) {
    const checked : boolean = filterValue;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds,
                      value: "online",
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="hide-offline"
                    size="small"
                />
            }
            label="Hide offline devices"
        />
    );
}

export function OnlineColumnFilter2({
    column: { filterValue, setFilter },
}: FilterProps<DeviceDto>) {
    const checked : boolean = filterValue;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setFilter(
            newChecked
                ? {
                      columnIds: ["connected"],
                      value: "True",
                  }
                : undefined
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!!checked}
                    onChange={handleChange}
                    name="hide-offline"
                    size="small"
                />
            }
            label="Hide offline devices"
        />
    );
}

export function AutocompleteColumnFilter({
    column: { filterValue = null, setFilter, preFilteredRows, id, render },
}: FilterProps<DeviceDto>) {

    const translator = useTranslator();
    const options_ = useMemo(() => {
        const options = new Set<any>();
        preFilteredRows.forEach((row) => {
            const value = (row.values[id]);
            if (value !== undefined) {
                options.add(value);
            }
        });
        return Array.from(options.values()).map((val) => ({
            value: val,
            display: translator.translate(id, val),
        }));
    }, [translator, preFilteredRows, id]);

    return (
        
            (filterValue?filterValue:[null]).map((filt:any,index:number)=>{

                return  <Autocomplete
                    value={filt}
                    options={options_}
                    clearOnBlur={false}
                    onChange={(_ev, opt) => {
                        setFilter((prevValue:any)=>{
                            if (filterValue){        
                                prevValue[index]=opt?.value;
                                return prevValue.filter((e:any)=>e).length===0?undefined:prevValue.filter((e:any)=>e)}
                            else 
                               return opt?.value?[opt?.value]:undefined
                        });
                    }}
                    key={index}
                    renderInput={(props) => {
                        return (
                            <TextField
                                margin="dense"
                                variant="outlined"
                                label={render("Header")}
                                {...props}
                            />
                        );
                    }}
                    getOptionLabel={(l) => {
                        if (l === null) {
                            return "";
                        }
                        return String( typeof l === "object"
                            ? l.display
                            : translator.translate(id, l));
                    }}
                    getOptionSelected={(opt, val) => {
                        // eslint-disable-next-line
                        return opt.value == val;
                    }}
                />
            
       })
     );
}
