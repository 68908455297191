import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { CircularProgress } from "@material-ui/core";

import PrivateRoute from "./components/PrivateRoute";

import Header from "./views/Header";
import Content from "./views/Content";
import ToastMessage from "./views/ToastMessage";

import NotFound from "./views/NotFound";
import Devices from "./views/Devices";
import Settings from "./views/Settings";
import { DealerMaterials } from "./views/DealerMaterials";
import { SupportLog } from "./views/Support";
import { SupportIssueView } from "./views/Support/SupportIssue";
import DialogBox from "./components/DialogBox";
import MapOfRobots from "./views/Map/MapOfRobots";
import { AdministrationOperations, UserActionsLog } from "./views/Administration";
import { useEvents } from "./hooks";
import { signOut } from "./redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "./views/Account/SignIn";
import CreateAccount from "./views/Account/CreateAccount";
import ViewAccount from "./views/Account/ViewAccount";
import EditAccount from "./views/Account/EditAccount";
import AccountOverview from "./views/Account/AccountOverview";
import ForgotPassword from "./views/Account/ForgotPassword";
import ResetPassword from "./views/Account/ResetPassword";
import VerifyEmail from "./views/Account/VerifyEmail";
import { Changelog } from "./views/Changelog";
import TreeOverview from "./views/Customer/TreeOverview";
import { utcTimezone } from "./utils/common";
import { timezoneSelector } from "./redux/app/selectors";
import { Developer } from "./views/Developer";
import { Production } from "./views/Production";
import QuoteGenerator from "./views/QuoteGenerator";
import { HistoryOverview } from "./views/HistoryOverview/HistoryOverview";
import Sections from "./views/DatabaseTool/Sections";
import DataExtractor from "./views/DataExtractor/DataExtractor";
import Savings from "./views/SavingsCalculator/Savings";
import { QCApp } from "./views/QCApp/QCApp";
import { CommandsTab } from "./views/Commands/Commands";

export let timezone = utcTimezone*60;

function App() {
    const dispatch = useDispatch();
    timezone = useSelector(timezoneSelector)*60;
    useEvents("logout", (e) => {if (e.type === "logout") dispatch(signOut())});
    useEffect(() => {
        if(window.localStorage.getItem('version') !== version){
            window.localStorage.setItem('version', version);
            dispatch(signOut());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <CacheBuster
                currentVersion={version}
                isEnabled={true}
                isVerboseMode={true}
                loadingComponent={<div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress size={70}/></div>}
            ><div>
            <Header />
            <Content>
                <Switch>
                    <Route exact path="/account/signin">
                        <SignIn />
                    </Route>
                    <Route exact path="/account/forgotpassword">
                        <ForgotPassword />
                    </Route>
                    <Route exact path="/account/resetpassword">
                        <ResetPassword />
                    </Route>
                    <Route exact path="/account/setpassword">
                        <ResetPassword isReset={false}/>
                    </Route>
                    <Route exact path="/account/verifyemail">
                        <VerifyEmail />
                    </Route>
                    <PrivateRoute needVerified exact path="/production">
                        <Production />
                    </PrivateRoute>
                    <PrivateRoute needVerified exact path="/accounts/create">
                        <CreateAccount />
                    </PrivateRoute>
                    <PrivateRoute exact path="/account/me">
                        <ViewAccount />
                    </PrivateRoute>
                    <PrivateRoute exact path="/account/me/edit">
                        <EditAccount />
                    </PrivateRoute>
                    <PrivateRoute needVerified exact path="/accounts">
                        <AccountOverview />
                    </PrivateRoute>
                    <PrivateRoute needVerified exact path="/accounts/:username">
                        <ViewAccount />
                    </PrivateRoute>
                    <PrivateRoute needVerified exact path="/accounts/:username/edit">
                        <EditAccount />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/developer">
                        <Developer />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/devices">
                        <Devices />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/customers">
                        <TreeOverview />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/settings">
                        <Settings />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/dealermaterials">
                        <DealerMaterials />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/quotegenerator">
                        <QuoteGenerator />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/map">
                        <MapOfRobots />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/administration/operations">
                        <AdministrationOperations />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/administration/log">
                        <UserActionsLog />
                    </PrivateRoute>
                    <PrivateRoute path="/support">
                        <Switch>
                            <PrivateRoute needVerified exact path={`/support/:supportId([+-]?\\d+)`}>
                                <SupportIssueView />
                            </PrivateRoute>
                            <PrivateRoute needVerified exact path="/support">
                                <SupportLog />
                            </PrivateRoute>
                            <PrivateRoute>
                                <NotFound />
                            </PrivateRoute>
                        </Switch>
                    </PrivateRoute>
                    <Route exact path="/">
                        <Redirect to="/devices/robots" />
                    </Route>
                    <PrivateRoute needVerified path="/changelog">
                        <Changelog />
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/history">
                        <HistoryOverview height="100vh" /> 
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/dbtool">
                        <Sections height="100vh" /> 
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/data-extractor">
                        <DataExtractor /> 
                    </PrivateRoute>
                    <PrivateRoute needVerified path={"/savings"}>
                        <Savings></Savings>
                    </PrivateRoute>
                    <PrivateRoute needVerified path="/QCApp">
                        <QCApp></QCApp>
                    </PrivateRoute>
                    <PrivateRoute needVerified path={"/commands"}>
                        <CommandsTab></CommandsTab>
                    </PrivateRoute>
                    <PrivateRoute path="*">
                        <NotFound />
                    </PrivateRoute>
                </Switch>
            </Content>
            <ToastMessage />
            <DialogBox />
            </div></CacheBuster>
        </div>
    );
}

export default App;