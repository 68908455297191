import {   Button,  FormControl,  Grid,  InputLabel,  MenuItem,  Select,  TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { useAsync, useToast } from "src/hooks";
import { userSelector } from "src/redux/app/selectors";
import DeviceDataService from "src/services/DeviceDataService";
import QCService, { Chapter } from "src/services/QCService";

function b64toBlob(b64Data:string, contentType:any, sliceSize:any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  export type QCquestion={
    name:string,
    description:Array<{image:string|Blob,text:string}>,
    questionConfig:{
        type:string,
        pictureRequired:string,
        subtype:string,
    },
    robotCommand?:any,
    robotTest?:any
}


export function QCApp() {
    const [name,setName]=useState(undefined as never as string)
    const [questions,setQuestions]=useState([] as QCquestion[])
    const [workflow,setWorkflow]=useState(undefined as any)
    const user=useSelector(userSelector)

    const fetchChapters = useCallback(
        () => QCService.GetChapters().then((r)=>{setWorkflow(r);return r.chapters as Chapter[]}),
        []
    );
    const { value:chapters } = useAsync(fetchChapters, {
        defaultPending: true,
    });

    const fetchKeys = useCallback(
        () => DeviceDataService.getDeviceDataKeys(),
        []
    );
    const { value:keys } = useAsync(fetchKeys, {
        defaultPending: true,
    });
    
    const { displayToast } = useToast();

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });

    const submit=async (e:any)=>{
        e.preventDefault()
        const json=workflow
        json.version=(Number(json.version)+1).toString();
        json.created=(new Date().valueOf()/1000).toString()
        json.createdBy=user.username
        for (const [queindex,que] of questions.entries())
        {
            for (const [descindex,desc] of que.description.entries())
            {
                if (desc.image)
                {
                    questions[queindex].description[descindex].image=btoa(String.fromCharCode(...new Uint8Array(await (desc.image as Blob).arrayBuffer())))
                }
            }
        }
        json.chapters[json.chapters.findIndex((n:any)=>n.name===name)].questions=questions;
        json.chapters[json.chapters.findIndex((n:any)=>n.name===name)].version=(Number(json.chapters[json.chapters.findIndex((n:any)=>n.name===name)].version)+1).toString();

  
        try {
            
            QCService.postChapter(json).then((r)=>{
                displayToast({message:"Changes are saved",severity:"success"});setQuestions([]);setName(undefined as never as string)
            })
        } catch (error) {
            displayErrorToast(error)
        }
        
    }
    return (
        <Grid container direction="column" style={{padding:20}}>
            <Typography variant="h5">QC App thingy</Typography>
           
            <form >
                <Grid container direction="column" spacing={3} style={{padding:50}}>
                    <Grid item>
                        <AutocompleteV2
                            options={chapters??[]}
                            renderInput={
                                params => 
                                { 
                                return <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Edit existing chapter"
                                />}
                            }
                            getOptionLabel={(c=>c.name)}
                            getOptionSelected={(a, b) => a.name === b.name}
                            renderOption={(c)=>{
                            return <div>{c.name }</div>}}
                            onChange={async (_e, val) => {
                                if (val){
                                    setName(val.name)
                                    
                                    for (const [queindex,que] of val.questions.entries())
                                    {
                                        for (const [descindex,desc] of que.description.entries())
                                        {
                                            if (desc.image)
                                            {
                                                const blob=new Blob([ (b64toBlob(await QCService.getImage(desc.image as string),null,null))])
                                                val.questions[queindex].description[descindex].image=blob
                                            }
                                        }
                                    }
                                    setQuestions(val.questions)
                                }
                            }}
                            style={{marginBottom:"10px",width:"400px"}}
                        ></AutocompleteV2>
                    </Grid>

                    {/* <Grid item>
                        <TextField label="Name" variant="outlined"  onChange={(e)=>setName(e.target.value)} required size="small" value={name ??''} style={{width:'400px'}}/>
                    </Grid> */}
                    <Grid item container spacing={2}>
                        {questions.map((que,index)=>{
                        return <Accordion style={{width:"100%"}} key={index}>
                            <AccordionSummary>
                                <Typography>{index +'. '+que.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                             <Grid container direction="column" spacing={2} style={{marginLeft:30,marginBottom:35,padding:15, border:'2px solid grey', borderRadius:5,width:"80%"}}  >
                            <Grid item>
                                <TextField label="Question Name" variant="outlined"  onChange={(e)=>{questions[index].name=e.target.value; setQuestions([...questions])}} required size="small" value={que.name} style={{width:'800px'}}/>
                            </Grid>
                            {que.description.map((desc,ind)=>{
                             
                            return <Grid item container key={ind} style={{border:'2px solid grey', width:"1100px",margin:'5px'}}> 
                                <Grid item container style={{marginTop:"50px"}}>                                                           
                                        <Grid item><input
                                            style={{maxWidth:"400px"}}
                                            type="file"
                                            name="myImage"
                                            onChange={async (event) => {
                                                if (event.target.files?.[0]){
                                                    const blob=new Blob([event.target.files?.[0]])
                                                    questions[index].description[ind].image=blob
                                                    setQuestions([...questions]);}
                                                }}
                                        /></Grid>
                                        {desc.image && <Grid item><img src={URL.createObjectURL(desc.image as Blob)} alt="problems :(" style={{maxWidth:100}}></img></Grid>}
                                        
                            </Grid>
                               <Grid item style={{marginTop:"10px"}}>
                               <TextField label="Description text" variant="outlined"  onChange={(e)=>{questions[index].description[ind].text=e.target.value; setQuestions([...questions])}} required size="small" value={desc.text} style={{width:'1000px'}}/>
                                </Grid>
                                        <Button variant="contained" color="primary" onClick={()=>{questions[index].description.splice(ind,1);setQuestions([...questions])}}>Delete</Button>
                                    </Grid>
                                })}
                            <Grid item style={{marginTop:5}}>
                                <Button variant="contained" color="primary" onClick={()=>{questions[index].description.push({image:undefined as unknown as string,text:""});setQuestions([...questions])}} style={{width:'400px'}}>Add description</Button>
                            </Grid>
                            <Grid item>
                                <FormControl size="small" style={{width:'400px'}}>
                                    <InputLabel id="select-label" style={{marginLeft:12,marginTop:-8}}>Question Type</InputLabel>
                                    <Select   labelId="select-label" label="Type" variant="outlined"  onChange={(e)=>{
                                        if (e.target.value==='test' || e.target.value==='command')
                                        {
                                            questions[index].questionConfig.type="automatic"
                                            questions[index].questionConfig.subtype=e.target.value
                                            if (e.target.value==='command')
                                            {questions[index].robotCommand={commandType:"call_ros_service",expected:[],args:{version:1}};questions[index].robotTest=undefined}
                                            if (e.target.value==='test')
                                            {questions[index].robotTest={args:{leftType:"db",leftValue:"",rightType:"value",rightValue:"",operator:"="}};questions[index].robotCommand=undefined}
                                        }   
                                        else
                                        {
                                            questions[index].questionConfig.type="manual"
                                            questions[index].robotCommand=undefined
                                            questions[index].robotTest=undefined
                                        }

                                        
                                        setQuestions([...questions])}
                                        }  required  value={ que.questionConfig.type==='manual'?"manual":que.questionConfig.subtype} >
                                        <MenuItem value={'test'} >Database checkup</MenuItem>
                                        <MenuItem value={'command'}>Robot Command</MenuItem>
                                        <MenuItem value={'manual'} >Manual</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>

                            <InputLabel id="select-label5" style={{marginLeft:12,marginTop:0}}>Picture required</InputLabel>

                            <Select   labelId="select-label5" style={{width:'400px'}} label="Picture required" variant="outlined"  onChange={(e)=>{questions[index].questionConfig.pictureRequired=e.target.value as string; setQuestions([...questions])}}  required  value={que.questionConfig.pictureRequired??""} >
                                                        <MenuItem value={'always'} >{'Always'}</MenuItem>
                                                        <MenuItem value={'if passed'} >{'If passed'}</MenuItem>
                                                        <MenuItem value={'if failed'} >{'If failed'}</MenuItem>
                                                        <MenuItem value={'optional'} >{'Optional'}</MenuItem>
                                                        <MenuItem value={'no'} >{'No'}</MenuItem>
                                                    </Select>
                            </Grid>
                            <Grid item container direction="row">
                                {que.questionConfig.subtype==='test' && que.robotTest &&
                                 <Grid item style={{padding:10,margin:10,marginLeft:30, border:'2px solid grey', borderRadius:5}}>
                                    <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                        <InputLabel id="select-label1" style={{marginLeft:12,marginTop:-8}}>Table</InputLabel>
                                        <Select   labelId="select-label1" label="Table" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.leftValue=e.target.value+'.'+que.robotTest.args.leftValue.split(".")[1] as string; setQuestions([...questions])}}  required  value={que.robotTest.args.leftValue.split('.')[0]??""} >
                                            <MenuItem value={'latestdevice_data'} >Latest Device Data</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                        <InputLabel id="select-label2" style={{marginLeft:12,marginTop:-8}}>Key</InputLabel>
                                        <Select   labelId="select-label2" label="Key" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.leftValue=que.robotTest.args.leftValue.split(".")[0]+'.'+e.target.value as string; setQuestions([...questions])}}  required  value={que.robotTest.args.leftValue.split('.')[1]??""} >
                                            {keys?.map((key,ind)=><MenuItem value={key} key={ind}>{key}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                        <InputLabel id="select-label3" style={{marginLeft:12,marginTop:-8}}>Inequality symbol</InputLabel>
                                        <Select   labelId="select-label3" label="Inequality symbol" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.operator=e.target.value as string; setQuestions([...questions])}}  required  value={que.robotTest.args.operator??""} >
                                            <MenuItem value={'='} >=</MenuItem>
                                            <MenuItem value={'>'} >{'>'}</MenuItem>
                                            <MenuItem value={'<'} >{"<"}</MenuItem>
                                            <MenuItem value={'>='} >{">="}</MenuItem>
                                            <MenuItem value={'<='} >{"<="}</MenuItem>
                                            <MenuItem value={'<>'} >!=</MenuItem>
                                            <MenuItem value={'range'} >Range(space separated)</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                        <InputLabel id="select-label4" style={{marginLeft:12,marginTop:-8}}>Value type</InputLabel>
                                        <Select   labelId="select-label4" label="Value type" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.rightType=e.target.value as string; setQuestions([...questions])}}  required  value={que.robotTest.args.rightType??""} >
                                            <MenuItem value={'value'} >{'Value'}</MenuItem>
                                            <MenuItem value={'db'} >{'Database'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {que.robotTest.args.rightType==='value'&&
                                        <TextField label="Value" type="text" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.rightValue=e.target.value; setQuestions([...questions])}} required size="small" value={que.robotTest.args.rightValue} style={{width:'100%',marginTop:10}}/>
                                    }
                                    {que.robotTest.args.rightType==='db' &&
                                        <Grid item>
                                            <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                                <InputLabel id="select-label1" style={{marginLeft:12,marginTop:-8}}>Table</InputLabel>
                                                <Select   labelId="select-label1" label="Table" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.rightTable=e.target.value as string; setQuestions([...questions])}}  required  value={que.robotTest.args.rightTable??""} >
                                                    <MenuItem value={'latest'} >Latest Device Data</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                                <InputLabel id="select-label2" style={{marginLeft:12,marginTop:-8}}>Key</InputLabel>
                                                <Select   labelId="select-label2" label="Key" variant="outlined"  onChange={(e)=>{questions[index].robotTest.args.rightKey=e.target.value as string; setQuestions([...questions])}}  required  value={que.robotTest.args.rightKey??""} >
                                                    {keys?.map((key,ind)=><MenuItem value={key} key={ind}>{key}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>}
                                {que.questionConfig.subtype==='command' && que.robotCommand && 
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TextField label="Robot Command" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.commandType=e.target.value; setQuestions([...questions])}}  required size="small" value={que.robotCommand.commandType} style={{width:'400px'}}/>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1} >
                                       
                                        <Grid item container spacing={1}>
                                            <Grid item>
                                                <TextField label="Version" variant="outlined" type="number" onChange={(e)=>{questions[index].robotCommand.args.version=Number(e.target.value); setQuestions([...questions])}} required size="small" value={que.robotCommand.args.version} style={{width:'100px'}}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField label="Service args for Command" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.args.service_args=e.target.value; setQuestions([...questions])}} required size="small" value={que.robotCommand.args.service_args} style={{width:'796px'}}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField label="Service namespace for Command" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.args.service_namespace=e.target.value; setQuestions([...questions])}} required size="small" value={que.robotCommand.args.service_namespace} style={{width:'296px'}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1} >
                                        {que.robotCommand.expected.map((arg:any,ind:number)=>{
                                            return <Grid item container  key={ind} spacing={1} style={{marginTop:"15px"}}>
                                                <FormControl size="small" style={{width:'396px'}}>
                                                    <InputLabel id="select-label4" style={{marginLeft:12,marginTop:-8}}>Type</InputLabel>
                                                    <Select   labelId="select-label4" label="Expected type" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.expected[ind].type=e.target.value as string; setQuestions([...questions])}}  required  value={que.robotCommand.expected[ind].type??""} >
                                                        <MenuItem value={'value_exact'} >{"result[key]=value"}</MenuItem>
                                                        <MenuItem value={'value_range'} >{'result[key] is in range (space separated)'}</MenuItem>
                                                        <MenuItem value={'value_in_array'} >{'result[key] is in [2,3,4,5]'}</MenuItem>
                                                        <MenuItem value={'array_range'} >{'result[key].every is in range (space separated)'}</MenuItem>
                                                        <MenuItem value={'array_includes'} >{'result[key] is array and includes value'}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                 <Grid item>
                                                    <TextField label="Expected Key" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.expected[ind].key=e.target.value; setQuestions([...questions])}} required size="small" value={arg.key} style={{width:'496px'}}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextField label="Expected Value" variant="outlined"  onChange={(e)=>{questions[index].robotCommand.expected[ind].value=e.target.value; setQuestions([...questions])}} required size="small" value={arg.value} style={{width:'596px'}}/>
                                                </Grid>
                                               <a href="https://docs.google.com/document/d/1qcGfc5QJu-H-aNptMEB2R-aM-ZKAw73uz30fJ6jJDhE/edit?usp=sharing">Documentation</a>
                                            </Grid>
                                        })}
                                        <Button variant="contained" color="primary" onClick={()=>{
                        questions[index].robotCommand.expected.push({type:"",key:"",value:""})
                        setQuestions([...questions])
                        }} style={{width:'400px'}}>Add expected</Button>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>   
                        </AccordionDetails> 
                    </Accordion>

                        
                    })}
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={()=>{
                        questions.push({name:undefined as unknown as string,description:[{text:"",image:""}],questionConfig:{type:"manual",pictureRequired:"no" }} as QCquestion)
                        setQuestions([...questions])
                        }} style={{width:'400px'}} disabled={!name || (chapters?.filter(c => c.name===name)[0]?.questions?.length !== 0 && questions.length === 0)}>Add question</Button>
                    </Grid>
                    <Grid item>
                    <Button variant="contained" color="primary" size="large" style={{width:'400px'}} onClick={(e)=>submit(e)} disabled={!name || (chapters?.filter(c => c.name===name)[0]?.questions?.length !== 0 && questions.length === 0)} type="submit">
                        Submit
                    </Button>
                    </Grid>
                </Grid>
                </Grid>
            </form>
        </Grid>)
    
}